import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { apiGetEmployeeBy } from "../../../api/employee";
import { showErrorToast } from "../../../utils/utilFunctions";
import { TRAINERS_POSITIONS } from "../../../utils/utilConstants";
import { apiCreateSeminar, apiGetSeminarById, apiUpdateSeminar } from "../../../api/seminars";

const CreateSeminar = ({ positions }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { seminarId } = useParams();
    const currDate = new Date();
    currDate.setSeconds(0);
    currDate.setMilliseconds(0);
    currDate.setMinutes(0);

    const [formData, setFormData] = useState({
        startTime: currDate.toISOString().slice(0, 19),
        endTime: currDate.toISOString().slice(0, 19),
        trainers: [],
        selectedTrainer: '',
        name: '',
        location: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const getTrainers = async () => {
        const trainersPositionsIds = positions
            .filter(position => TRAINERS_POSITIONS.includes(position.name.toLowerCase()))
            .map(trainer => trainer.id);
        apiGetEmployeeBy((trainers) => {
            setFormData((prevFormData) => ({ ...prevFormData, trainers }));
        }, showErrorToast, `position_id=${trainersPositionsIds}`);
    };

    useEffect(() => {
        getTrainers();

    }, []);

    useEffect(() => {
    }, [formData])

    useEffect(() => {
        if (seminarId && seminarId !== "0") {
            apiGetSeminarById(parseSeminarResponse, showErrorToast, seminarId)
        }

    }, [seminarId]);
    const parseSeminarResponse = (data) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            startTime: data.date_start ? data.date_start.slice(0, 19) : prevFormData.startTime,
            endTime: data.date_end ? data.date_end.slice(0, 19) : prevFormData.endTime,
            selectedTrainer: data.trainer_id,
            name: data.name,
            location: data.location,
        }));
    }
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toISOString().slice(0, 19).replace("T", " ");
    };
    const submit = async () => {
        const { startTime, endTime, selectedTrainer, name, location } = formData;
        const formattedData = {
            date_start: formatDate(startTime),
            date_end: formatDate(endTime),
            trainer_id: selectedTrainer,
            name,
            location,
        };
        if (seminarId === '0') {
            apiCreateSeminar(() => { navigate(-1); }, showErrorToast, { ...formattedData });
        } else {
            apiUpdateSeminar(() => { navigate(-1); }, showErrorToast, { ...formattedData }, seminarId);
        }
    };

    return (
        <div className="space-y-4">
            <Typography variant="h4">
                <span className="font-bold text-black">{seminarId === "0" ? "Create Seminar" : "Editeaza seminar"}</span>
            </Typography>
            <Select
                name="selectedTrainer"
                value={formData.selectedTrainer || ""}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
                className=""
            >
                <MenuItem value="" disabled>{t('Select Trainer')}</MenuItem>
                {formData.trainers.map((trainer) => (
                    <MenuItem key={trainer.id + "select"} value={trainer.id}>
                        {trainer.name}
                    </MenuItem>
                ))}
            </Select>
            <TextField
                label="Denumire"
                type="string"
                name="name"
                value={formData.name || ""}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
                required
            />
            <TextField
                label="Data de inceput"
                type="datetime-local"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
                inputProps={{
                    min: new Date().toISOString().slice(0, 16),
                }}
            />
            <TextField
                label="Data de sfarsit"
                type="datetime-local"
                name="endTime"
                value={formData.endTime}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
                inputProps={{
                    min: new Date().toISOString().slice(0, 16),
                }}
            />
            <TextField
                label="Locatia"
                type="string"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                style={{ marginTop: '1rem' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button onClick={submit} variant="contained" color="primary">
                    {seminarId === "0" ? "Adauga Seminar" : "Actualizeaza"}
                </Button>
            </Box>
        </div>
    );
};
export default CreateSeminar;