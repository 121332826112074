import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './layouts/Dashboard';
import Auth from './layouts/Auth';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import MobileApp from './layouts/MobileApp';
import FuchsiaTheme from './theme/FuchsiaTheme';

const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  const [isFuchsia, setIsFuchsia] = useState(false);

  const toggleTheme = () => {
    setIsFuchsia((prevMode) => !prevMode);
  };

  return (
    <ThemeProvider theme={isFuchsia ? FuchsiaTheme : theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard/*" element={<Dashboard toggleTheme={toggleTheme} isFuchsia={isFuchsia}/>} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/app/*" element={<MobileApp />} />
          <Route path="*" element={<Navigate to="/auth" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

root.render(<App />);

reportWebVitals();