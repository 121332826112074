// src/theme.js
import { createTheme } from '@mui/material/styles';

// Create a custom theme with the desired font
const FuchsiaTheme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif", // Apply your custom font here
  },
  // You can also customize other aspects of the theme here
  palette: {
    primary: {
      main: '#FF00FF',
    },
    secondary: {
      main: '#dc004e',
    },
    primaryApp: {
      main: '#00FF00',
    }
  },
});

export default FuchsiaTheme;
