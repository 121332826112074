import {
    Typography,
    TextField,
    Button,
    Box,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate(); // Initialize navigate function
    const { t } = useTranslation();

    // State for form fields and errors
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        verifyPassword: '',
    });

    // Validate form fields
    const validateForm = () => {
        let valid = true;
        let newErrors = {
            email: '',
            password: '',
            verifyPassword: '',
        };

        if (!email) {
            newErrors.email = t('email-required');
            valid = false;
        }

        if (!password) {
            newErrors.password = t('password-required');
            valid = false;
        }

        if (password !== verifyPassword) {
            newErrors.verifyPassword = t('passwords-not-match');
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const register = async () => {
        if (!validateForm()) {
            return; // Prevent API call if validation fails
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        try {
            const response = await fetch(`${apiUrl}/api/register`, {
                method: 'GET', // or 'GET' depending on your APIS
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.success) {
                navigate('/auth/login');
            } else {
                toast.error(t('something-went-wrong'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <Typography variant="h3">{t('register')}</Typography>

            <Typography variant="subtitle1" gutterBottom>
                {t('create-account')}
            </Typography>
            <Box component="form" noValidate autoComplete="off">
                <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    fullWidth
                    margin="normal"
                    label={t('password')}
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <TextField
                    sx={{ mb: 3 }}
                    fullWidth
                    margin="normal"
                    label={t('verify-password')}
                    variant="outlined"
                    type="password"
                    value={verifyPassword}
                    onChange={(e) => setVerifyPassword(e.target.value)}
                    error={!!errors.verifyPassword}
                    helperText={errors.verifyPassword}
                />
                <Button variant="contained" color="primary" fullWidth onClick={register}>
                    {t('register')}
                </Button>
            </Box>
            <a className="text-blue-500 hover:text-blue-700 underline" href="/auth/login">{t('already-have-account')}</a>
        </>
    );
};

export default Register;
