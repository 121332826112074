import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    apiGetSeminars, apiGetEmployeesBySeminarId,
    apiExportExcel, apiGetEmployeesForInactiveSeminars
} from "../../../api/seminars";
import { apiGetUserRightsForLocation } from "../../../api/rights"
import GenericTable from "../../../components/GenericTable";
import { showErrorToast, showSuccessToast } from "../../../utils/utilFunctions";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, List, CircularProgress, Typography, ListItem, IconButton, ListItemText } from '@mui/material';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'trainer_id', headerName: 'Trainer id', type: 'string' },
    { field: 'name', headerName: 'Denumire seminar', type: 'string' },
    { field: 'date_start', headerName: 'Data de incepere', type: 'datetime' },
    { field: 'date_end', headerName: 'Data de sfarsit', type: 'datetime' },
    { field: 'location', headerName: 'Locatia', type: 'string' },
    { field: 'created_at', headerName: 'created_at', type: 'date' },

];
const InactiveSeminars = ({ selectedLocation }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [actions, setActions] = useState([]);
    const [openAddEmployeeDialog, setOpenAddEmployeeDialog] = useState(false); // New state for employee dialog
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [seminarId, setSeminarId] = useState(null);
    const [hasRights, setHasRights] = useState(false);
    const [selectedAllEmployees, setSelectedAllEmployees] = useState([]);
    useEffect(() => {
        if (selectedLocation) {
            apiGetSeminars((seminars) => {
                const inactiveSeminars = seminars.filter(seminar => seminar.active === 0);
                setData(inactiveSeminars)
            }, showErrorToast);

            apiGetUserRightsForLocation((employees) => {
                if (employees[0].right_code === 2 || employees[0].right_code === 3) {
                    setHasRights(true);
                }
            }, showErrorToast, selectedLocation.id)
            apiGetEmployeesForInactiveSeminars((response) => {
                setSelectedAllEmployees(response.data);
                console.log('response', response.data);
            }, showErrorToast)
        } else {
            navigate('/dashboard');
        }

    }, [hasRights]);

    useEffect(() => {
        let actionsTmp = [];
        if (hasRights === true) {
            actionsTmp = [
                { icon: (<AccountCircleIcon />), color: 'black', onClick: (id) => handleFetchEmployees(id) }
            ];
        }
        setActions(actionsTmp);
    }, [hasRights]);

    const handleFetchEmployees = (id) => {
        setSeminarId(id);
        setSelectedEmployees([])
        apiGetEmployeesBySeminarId(
            (employees) => {
                setSelectedEmployees(employees);
            },
            id
        );
        setOpenAddEmployeeDialog(true)
    };

    // Close the add employee dialog
    const handleCloseAddEmployeeDialog = () => {
        setOpenAddEmployeeDialog(false);
    };
    const handleExportSeminars = (data) => {
        apiExportExcel((data) => {
            if (!data) {
                return;
            }
            // Create a blob from the received data
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Generate a download link for the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "inactive_seminars.xlsx"; // Desired file name
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url); // Clean up the URL
        }, showErrorToast)
    };
    const childrenData = selectedAllEmployees.reduce((acc, employee) => {
        const seminarId = employee.seminar_id;

        if (!acc[seminarId]) {
            acc[seminarId] = [];
        }

        acc[seminarId].push({ name: employee.name });
        return acc;
    }, {});

    const childrenColumns = [
        { field: 'name' }
    ];
    return (
        <>
            <GenericTable
                actions={actions}
                title={"Inactive seminars"}
                buttonText={hasRights === true ? "Active Seminars" : ""}
                buttonAction={() => { navigate('/dashboard/seminars') }}
                btnText={hasRights === true ? "Export seminare" : ""}
                btnAction={() => {
                    handleExportSeminars(data);
                }}
                columns={columns}
                data={data}
                childrenColumns={childrenColumns}
                childrenData={childrenData}
                isExtendedTable={true}
            />
            {/* Add Employee Dialog */}
            <Dialog open={openAddEmployeeDialog} onClose={handleCloseAddEmployeeDialog} fullWidth maxWidth="sm">
                <DialogTitle>Vezi angajati</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAddEmployeeDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Typography variant="h6" sx={{ marginTop: 2 }}>Angajati selectati:</Typography>
                    <List>
                        {selectedEmployees.map((employee) => (
                            <ListItem
                                key={employee.id}
                            >
                                <ListItemText primary={employee.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
};
export default InactiveSeminars;