import { getToken } from "../utils/utilFunctions";

export const getBundlesAPI = async (successCallBack, errorCallBack, bundleId = "") => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles?bundle_id=${bundleId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 204) {
            successCallBack([])
        } else {
            const data = await response.json();
            if (!data.success) {
                successCallBack([])
            } else {
                successCallBack(data.data)
            }
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const getBundleMediaAPI = async (successCallBack, errorCallBack, bundleId = "") => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/media/${bundleId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 204) {
            successCallBack([])
        } else {
            const data = await response.json();
            if (!data.success) {
                successCallBack([])
            } else {
                successCallBack(data.data)
            }
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const toggleBundleAPI = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/toggle`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack("Ceva nu a mers bine")
    }
}


export const createBundleAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        console.log("DATA: ", data)
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.message, data.data.id)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const updateBundleAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles`, {
            method: 'PUT', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.message)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const addBundleMediaAPI = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        // Create a new FormData object
        const formData = new FormData();


        formData.append('bundle_id', reqData.bundle_id);

        if (reqData.photo) {
            formData.append('photo', reqData.photo); // Make sure `reqData.sent_proof` is a File object
        }

        const response = await fetch(`${apiUrl}/api/bundles/media`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const deleteBundleMediaAPI = async (successCallback, errorCallback, bundle_id) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/media/${bundle_id}`, {
            method: 'DELETE', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.message)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const getBundleItemsAPI = async (successCallBack, errorCallBack, bundleId = "") => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/items?bundle_id=${bundleId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 204) {
            successCallBack([])
        } else {
            const data = await response.json();
            if (!data.success) {
                successCallBack([])
            } else {
                successCallBack(data.data)
            }
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}

export const addBundleItemAPI = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/items`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });


        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const updateBundleItemAPI = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/bundles/items/${reqData.id}`, {
            method: 'PUT', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });


        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
