import { Box, Button, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useTranslation } from 'react-i18next';
import TabPanel from "../../../components/TabPanel";
import GenericTable from "../../../components/GenericTable";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import BundleSettings from "../../../components/bundles/BundleSettings";
import { createBundleAPI, getBundlesAPI, getBundleMediaAPI, addBundleMediaAPI, deleteBundleMediaAPI, getBundleItemsAPI, addBundleItemAPI, updateBundleAPI, updateBundleItemAPI } from "../../../api/bundles";
import { showErrorToast, showSuccessToast } from "../../../utils/utilFunctions";
import { useNavigate, useParams } from "react-router-dom";
import BundleItemSettings from "../../../components/bundles/BundleItemSettings";
import { getProductsWithVariantsAPI } from "../../../api/products";

const initialBundle = {
    title: '',
    description: '',
    date_type: '',
    date: '',
    discount_type: '',
    discount_value: '',
};

const initialBundleItem = {
    id: '',
    variant_id: '',
    product_id: '',
    quantity: '',
    discount_type: '',
    discount_value: ''
};

const CreateBundle = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [actions, setActions] = useState([])
    const [bundle, setBundle] = useState(initialBundle)
    const [media, setMedia] = useState([])
    const [bundleItems, setBundleItems] = useState([])
    const [bundleItemSelected, setBundleItemSelected] = useState(initialBundleItem)
    const [products, setProducts] = useState([])
    const { bundleId } = useParams();

    const columns = [
        { field: 'id', headerName: 'ID', type: 'string' },
        { field: 'variant_id', headerName: 'variant id', type: 'int_greater_0' },
    ];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        if (!bundleId || bundleId == 0) {
            setBundleItemSelected({ ...bundleItemSelected, bundle_id: bundleId })
            return;
        } else {
            getBundlesAPI((data) => { setBundle(data.length > 0 ? data[0] : []) }, showErrorToast, bundleId)
        }

    }, [])

    useEffect(() => {
        if (bundle.id) {
            console.log("GET HERE EFFECT BUNDLE")
            getBundleMedia()
            getBundleItems(bundle.id)
            getProductsWithVariants()
            setBundleItemSelected({ ...bundleItemSelected, bundle_id: bundle.id })
        }
    }, [bundle])

    const getProductsWithVariants = () => {
        getProductsWithVariantsAPI(setProducts, showErrorToast);
    }

    const getBundleMedia = () => {
        getBundleMediaAPI(setMedia, showErrorToast, bundle.id)
    }

    const getBundleItems = (forBundleId) => {
        getBundleItemsAPI(setBundleItems, showErrorToast, forBundleId)
    }

    const handleDeleteProductFromBundle = (id) => {

    }

    const handleSaveBundle = () => {
        if (!bundle.id) {
            createBundleAPI((message, id) => { showSuccessToast(message); setBundle({ ...bundle, id: id }) }, showErrorToast, { ...bundle, date: bundle.date })
        } else {
            updateBundleAPI(showSuccessToast, showErrorToast, { ...bundle, bundle_id: bundle.id })
        }
    }

    const handleSaveBundleItem = () => {
        if (bundleItemSelected.id) {
            updateBundleItemAPI(resetBundleItems, showErrorToast, { ...bundleItemSelected })
        } else {
            addBundleItemAPI(resetBundleItems, showErrorToast, { ...bundleItemSelected })
        }
    }

    const resetBundleItems = () => {
        if (!bundleId && bundleId !== 0) {
            getBundleItems(bundleId)
            setBundleItemSelected({ ...initialBundleItem, bundle_id: bundleId })
        } else {
            getBundleItems(bundle.id)
            setBundleItemSelected({ ...initialBundleItem, bundle_id: bundle.id })
        }
    }

    const handleDone = () => {
        navigate(-1);
    }

    const handleFileChange = (event) => {
        if (!bundle.id) {
            showErrorToast("Salvati bundleul mai intai!")
            return;
        }
        const file = event.target.files[0];

        addBundleMediaAPI(getBundleMedia, showErrorToast, { photo: file, bundle_id: bundle.id })
    };

    const handleDeleteMedia = (id) => {
        deleteBundleMediaAPI(getBundleMedia, showErrorToast, id)
    }

    const handleEditBundleItem = (id) => {
        setBundleItemSelected(bundleItems.filter(bundleItem => id === bundleItem.id)[0])
    }


    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="product tabs">
                        <Tab label="Bundle Settings" />
                        <Tab label="Bundle Products" />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <BundleSettings bundle={bundle} setBundle={setBundle} saveBundle={handleSaveBundle}></BundleSettings>
                    {/* File input for adding photos */}
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Button variant="contained" component="label">
                            Adauga poze
                            <input
                                type="file"
                                hidden
                                accept="image/*, video/*"
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                            {media.map((photo) => (
                                <Box
                                    key={photo.id}
                                    sx={{
                                        position: 'relative',
                                        width: 100,
                                        height: 100,
                                        borderRadius: 1,
                                        overflow: 'hidden',
                                        boxShadow: 1,
                                    }}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/${photo.path}`}
                                        alt="uploaded"
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            top: 4,
                                            right: 4,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            color: 'white',
                                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                                        }}
                                        size="small"
                                        onClick={() => handleDeleteMedia(photo.id)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <GenericTable
                        title={''}
                        edit={true}
                        onEdit={handleEditBundleItem}
                        columns={columns}
                        data={bundleItems}
                        actions={actions} />
                    <BundleItemSettings bundleItem={bundleItemSelected} setBundleItem={setBundleItemSelected} saveBundleItem={handleSaveBundleItem} products={products}></BundleItemSettings>
                </TabPanel>
                <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDone}
                    >
                        Done
                    </Button>
                </Box>
            </Box>
        </>
    )
}
export default CreateBundle;