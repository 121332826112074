import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    apiGetSeminars, apiDeleteSeminarById, apiSearchEmployee,
    apiAddEmployeesToSeminar, apiGetEmployeesBySeminarId,
    apiDeleteEmployeeByIdBySeminarId, apiGetEmployeeByIdBySeminarId, apiSendMessageToManagersForOneSeminar, apiGetEmployeesForActiveSeminars
} from "../../../api/seminars";
import { apiGetUserRightsForLocation } from "../../../api/rights"
import GenericTable from "../../../components/GenericTable";
import { showErrorToast, showSuccessToast } from "../../../utils/utilFunctions";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, List, CircularProgress, Typography, ListItem, IconButton, ListItemText } from '@mui/material';

const columns = [
    { field: 'id', headerName: 'ID', type: 'string' },
    { field: 'trainer_id', headerName: 'Trainer id', type: 'string' },
    { field: 'name', headerName: 'Denumire seminar', type: 'string' },
    { field: 'date_start', headerName: 'Data de incepere', type: 'datetime' },
    { field: 'date_end', headerName: 'Data de sfarsit', type: 'datetime' },
    { field: 'location', headerName: 'Locatia', type: 'string' },
    { field: 'created_at', headerName: 'created_at', type: 'date' },

];
const Seminars = ({ selectedLocation }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [actions, setActions] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [seminarToDelete, setSeminarToDelete] = useState(null);
    const [openAddEmployeeDialog, setOpenAddEmployeeDialog] = useState(false); // New state for employee dialog
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [seminarId, setSeminarId] = useState(null);
    const [hasRights, setHasRights] = useState(false);
    const [selectedAllEmployees, setSelectedAllEmployees] = useState([]);

    useEffect(() => {
        if (selectedLocation) {
            apiGetSeminars((seminars) => {
                const activeSeminars = seminars.filter(seminar => seminar.active === 1);
                setData(activeSeminars)
            }, showErrorToast);
            apiGetEmployeesForActiveSeminars((response) => {
                setSelectedAllEmployees(response.data);
            }, showErrorToast)
            apiGetUserRightsForLocation((employees) => {
                if (employees[0].right_code === 2 || employees[0].right_code === 3) {
                    setHasRights(true);
                }
            }, showErrorToast, selectedLocation.id)
        } else {
            navigate('/dashboard');
        }

    }, [hasRights]);

    // Function to open the delete confirmation dialog
    const handleOpenDialog = (seminarId) => {
        setSeminarToDelete(seminarId); // Store the seminar ID to be deleted
        setOpenDialog(true); // Open the dialog
    };

    useEffect(() => {
        let actionsTmp = [];
        if (hasRights === true) {
            actionsTmp = [
                { icon: (<AccountCircleIcon />), color: 'black', onClick: (id) => handleFetchEmployees(id) },
                { icon: <DeleteIcon />, color: 'red', onClick: handleOpenDialog }
            ];
        }
        setActions(actionsTmp);
    }, [hasRights]);

    const handleFetchEmployees = (id) => {
        setSeminarId(id);
        setSelectedEmployees([])
        apiGetEmployeesBySeminarId(
            (employees) => {
                setSelectedEmployees(employees);
            },
            id
        );
        setOpenAddEmployeeDialog(true)
    };
    const handleDeleteSeminarRequest = () => {
        apiDeleteSeminarById((response) => {
            showSuccessToast(response.message);
            const updatedData = data.filter((seminar) => seminar.id !== seminarToDelete);
            setData(updatedData);
            setOpenDialog(false);

        }, showErrorToast, seminarToDelete);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    // Function to fetch employees based on search term
    const fetchEmployeeSearchResults = async (search) => {
        setLoading(true);
        try {
            await apiSearchEmployee((employees) => {
                setSearchResults(employees);
            }, showErrorToast, search);

        } catch (error) {
            console.error("Error fetching employees:", error);
        } finally {
            setLoading(false);
        }
    };
    // Function to handle search input change with debounce
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        if (value.trim()) {
            fetchEmployeeSearchResults(value);
        } else {
            setSearchResults([]);
        }
    };
    const handleAddEmployee = (employee) => {
        apiAddEmployeesToSeminar(
            (addResponse) => {
                apiGetEmployeeByIdBySeminarId(
                    (fetchedEmployee) => {
                        setSelectedEmployees((prevEmployees) => [...prevEmployees, employee]);
                    },
                    showErrorToast,
                    employee.id,
                    seminarId
                );
                showSuccessToast(addResponse.message);
            },
            showErrorToast,
            {
                seminar_id: seminarId,
                employee_id: employee.id
            }
        );
        setSearchResults([]);
        setSearchTerm('');
    };
    // Function to handle successful deletion
    const handleDeleteSuccess = (deletedEmployeeId, response) => {
        // Remove the employee from the selectedEmployees list
        setSelectedEmployees((prevEmployees) =>
            prevEmployees.filter((employee) => employee.id !== deletedEmployeeId)
        );
        apiGetEmployeesForActiveSeminars((response) => {
            setSelectedAllEmployees(response.data);
        }, showErrorToast)
        showSuccessToast(response.message);
    };
    // Close the add employee dialog
    const handleCloseAddEmployeeDialog = () => {
        setOpenAddEmployeeDialog(false);
        setSearchTerm('');
        setSearchResults([]);
    };


    const childrenData = selectedAllEmployees.reduce((acc, employee) => {
        const seminarId = employee.seminar_id;

        if (!acc[seminarId]) {
            acc[seminarId] = [];
        }

        acc[seminarId].push({ name: employee.name });
        return acc;
    }, {});

    const childrenColumns = [
        { field: 'name' }
    ];
    return (
        <>
            <GenericTable
                actions={actions}
                edit={hasRights === true ? true : false}
                onEdit={(id) => { navigate(`/dashboard/seminars/createEditSeminar/${id}`) }}
                title={"Seminars"}
                buttonText={hasRights === true ? "Adauga Seminar" : ""}
                buttonAction={() => { navigate('/dashboard/seminars/createEditSeminar/0') }}
                btnText={hasRights === true ? "Istoric seminare" : ""}
                btnAction={() => { navigate('/dashboard/seminars/inactiveSeminars') }}
                columns={columns}
                data={data}
                childrenColumns={childrenColumns}
                childrenData={childrenData}
                isExtendedTable={true}
            />

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    Esti sigur ca vrei sa stergi acest seminar?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Anuleaza
                    </Button>
                    <Button onClick={handleDeleteSeminarRequest} color="secondary">
                        Sterge
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Employee Dialog */}
            <Dialog open={openAddEmployeeDialog} onClose={handleCloseAddEmployeeDialog} fullWidth maxWidth="sm">
                <DialogTitle>Adauga angajati la seminar</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Cauta angajati"
                        variant="outlined"
                        fullWidth
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ marginBottom: 2 }}
                    />

                    {loading ? <CircularProgress /> : (
                        <List>
                            {searchResults.map((employee) => (
                                <ListItem
                                    button
                                    key={employee.id}
                                    onClick={() => handleAddEmployee(employee)}
                                >
                                    {employee.name} {/* Assuming employee has a `name` property */}
                                </ListItem>
                            ))}
                        </List>
                    )}
                    <Typography variant="h6" sx={{ marginTop: 2 }}>Angajati selectati:</Typography>
                    <List>
                        {selectedEmployees.map((employee) => (
                            <ListItem
                                key={employee.id}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => apiDeleteEmployeeByIdBySeminarId(
                                        (response) => handleDeleteSuccess(employee.id, response),
                                        showErrorToast,
                                        employee.id,
                                        seminarId

                                    )} style={{ color: 'red' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={employee.name} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                        onClick={() => {
                            apiGetEmployeesForActiveSeminars((response) => {
                                setSelectedAllEmployees(response.data);
                            }, showErrorToast)
                            setOpenAddEmployeeDialog(false);
                        }}
                        color="success"
                    >
                        Finalizeaza
                    </Button>
                    <Button variant="outlined" endIcon={<SendIcon />}
                        onClick={() => {
                            apiSendMessageToManagersForOneSeminar((response) => {
                                showSuccessToast(response.message)

                            }, showErrorToast, seminarId)
                            setOpenAddEmployeeDialog(false);
                        }}
                        color="primary"

                    >
                        Trimite mesaj
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default Seminars;