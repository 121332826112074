import { getToken } from "../utils/utilFunctions";

export const apiGetSeminars = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getSeminars`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const apiGetSeminarById = async (successCallBack, errorCallBack, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getSeminarById?seminar_id=${seminarId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const apiCreateSeminar = async (successCallback, errorCallback, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/createSeminar`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}
export const apiUpdateSeminar = async (successCallback, errorCallback, reqData, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/updateSeminar/${seminarId}`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}
export const apiDeleteSeminarById = async (successCallback, errorCallback, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/deleteSeminar/${seminarId}`, {
            method: 'DELETE', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

        });

        const data = await response.json();
        console.log(data)
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

// Fetch employees by seminar ID
export const apiGetEmployeesBySeminarId = async (successCallBack, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getEmployees/${seminarId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Cache-Control': 'no-cache'
            }
        });
        const data = await response.json();
        if (!data.success) {
            //errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }

    } catch (error) {
        console.error('Error:', error);
        //errorCallBack(error)
    }
};

// Add employee to seminar
export const apiAddEmployeesToSeminar = async (successCallBack, errorCallBack, reqData) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/addEmployee`, {
            method: 'POST', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                ...reqData
            }),
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack("Ceva nu a mers bine")
    }
};

export const apiSearchEmployee = async (successCallback, errorCallback, searchField) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/employeesSearch?searchField=${searchField}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        const data = await response.json();
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}

export const apiDeleteEmployeeByIdBySeminarId = async (successCallback, errorCallback, employeeId, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/deleteEmployee/${employeeId}/${seminarId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },

        });

        const data = await response.json();
        console.log(data)
        if (!data.success) {
            errorCallback(data.message)
        } else {
            successCallback(data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallback("Ceva nu a mers bine")
    }
}
// Fetch employees by ID
export const apiGetEmployeeByIdBySeminarId = async (successCallBack, errorCallBack, employeeId, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getEmployee/${employeeId}/${seminarId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }

    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
};
export const apiSendMessageToManagersForOneSeminar = async (successCallBack, errorCallBack, seminarId) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/message/${seminarId}`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
        }

    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
};
export const apiGetInactiveSeminars = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getInactiveSeminars`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data.data)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const apiExportExcel = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/downloadExcel`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }

        })

            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch the Excel file.");
                }
                return response.blob(); // Read response as a binary blob
            })
            .then((blob) => {
                successCallBack(blob);
            })
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const apiGetEmployeesForActiveSeminars = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getEmployeesForActiveSeminars`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
            //successCallBack(data.message)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}
export const apiGetEmployeesForInactiveSeminars = async (successCallBack, errorCallBack) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    try {
        const response = await fetch(`${apiUrl}/api/seminars/getEmployeesForInactiveSeminars`, {
            method: 'GET', // or 'GET' depending on your APIS
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (!data.success) {
            errorCallBack(data.message)
        } else {
            successCallBack(data)
            //successCallBack(data.message)
        }
    } catch (error) {
        console.error('Error:', error);
        errorCallBack(error)
    }
}